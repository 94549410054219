import { Vue, Component } from 'vue-property-decorator';

import CoolLightBox from 'vue-cool-lightbox';

import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

/*
* Esse mixin é responsavel por buscar e aplicar o LightBox em todas as imagens que vem dentro do html da requisição
* Para que funcione de forma satisfatória, a tag na qual contém o V-HTML precisa ter uma REF nomeada com: contentHTML
*/

@Component({
  components: {
    CoolLightBox,
  },
})
export default class LishtBoxImage extends Vue {
  private arraySrcImages: Array<string> = [];
  private indexImage: number | null = null;

  mounted() {
    this.insertClickImage();
  }

  updated() {
    this.insertClickImage();
  }

  insertClickImage() {
    const { contentHTML }: any = this.$refs;

    if (contentHTML?.childNodes && contentHTML?.childNodes.length) {
      contentHTML.childNodes.forEach((node: any) => {
        this.searchImage(node);
      });
    }
  }

  searchImage(node: any) {
    if (!node?.childNodes || !node?.childNodes.length) {
      if (node?.src) {
        this.insertedClick(node);
      }

    } else if (node?.childNodes.length) {
      node.childNodes.forEach((nodeItem: any) => {
        this.searchImage(nodeItem);
      });
    }
  }

  insertedClick(element: any) {
    element.addEventListener('click', this.setArrySrcAndIndex);
    element.setAttribute('style', 'cursor: pointer');
  }

  setArrySrcAndIndex(event: any) {
    this.arraySrcImages = [event.target.src];
    this.indexImage = 0;
  }
}
